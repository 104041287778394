import API from '@sport-travel/api'

const legacyBlockBookingAPI = API.LegacyBlockBookingList
const legacyTeamBookingAPI = API.LegacyTeamBooking

const state = {
  loading: false,
  hotels: [],
  event: {},
  teamBooking: {},
  active: {}
}

const actions = {
  async fetchHotels (context, { params, headers }) {
    try {
      this.loading = true
      const { data } = await legacyBlockBookingAPI.fetch(params, headers)
      context.commit('SET_HOTELS', data.hotels)
      context.commit('SET_EVENT', data.event)
      return data
    } catch (err) {
      console.warn(err)
      throw err
    } finally {
      this.loading = false
    }
  },
  async fetchTeamBooking (context, { id, params, headers }) {
    try {
      this.loading = true
      const { data: teamBooking } = await legacyTeamBookingAPI.fetchById(id, params, headers)
      context.commit('SET_TEAM_BOOKING', teamBooking)
      return teamBooking
    } catch (err) {
      console.warn(err)
      throw err
    } finally {
      this.loading = false
    }
  },
  async acceptTeamBookingPolicies (context, { id, body, params, headers }) {
    try {
      this.loading = true
      const { data: teamBooking } = await legacyTeamBookingAPI.patch(id, { ...body, accept: true }, params, headers)
      // context.commit('SET_TEAM_BOOKING', teamBooking)
      return teamBooking
    } catch (err) {
      console.warn(err)
      throw err
    } finally {
      this.loading = false
    }
  },
  async createGroupRequest (context, { body, params, headers }) {
    try {
      this.loading = true
      const { data } = await legacyBlockBookingAPI.post(body, params, headers)
      return data
    } catch (err) {
      console.warn(err)
      throw err
    } finally {
      this.loading = false
    }
  },
  async createReservation (context, { body, params, headers }) {
    try {
      this.loading = true
      const { data } = await legacyTeamBookingAPI.post(body, params, headers)
      return data
    } catch (err) {
      console.warn(err)
      throw err
    } finally {
      this.loading = false
    }
  }
}

const mutations = {
  SET_HOTELS (state, value) {
    state.hotels = value
  },
  SET_TEAM_BOOKING (state, value) {
    state.teamBooking = value
  },
  SET_EVENT (state, value) {
    state.event = value
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}
